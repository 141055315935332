<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">新开校区</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>校区名字</span>
              </template>
              <a-input allowClear v-model.trim="searchData.studio_name" placeholder="校区名字" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>省市区</span>
              </template>
              <a-cascader :options="chinaDivision" :showSearch="true" placeholder="请选择省市区" change-on-select v-model="searchData.province" @change="onChangeOptions" style="width: 160px" />
            </a-tooltip>
          </a-form-item>
          <a-form-item>
              <a-tree-select
                  v-model="searchData.department_ids"
                  style="width:160px"
                  :tree-data="DepOptions"
                  tree-checkable
                  allowClear
                  :replaceFields="{
                      title:'department_name',
                      value: 'department_id',
                  }"
                  treeNodeFilterProp="title"
                  :maxTagCount='1'
                  :maxTagTextLength='5'
                  :dropdownStyle="{maxHeight:'400px'}"
                  :show-checked-strategy="SHOW_ALL"
                  :treeCheckStrictly="true"
                  placeholder="请选择部门"
              />
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>校区类型</span>
              </template>
              <a-radio-group :value="searchData.type">
                <a-radio-button v-for="(d, index) of studioType" :key="index" :value="d['value']" @click="handleTypeButChange(d,'type')">
                    {{ d['label'] }}
                </a-radio-button>
              </a-radio-group>
              <!-- <a-select allowClear v-model="searchData.type" placeholder="校区类型" style="width: 140px">
                <a-select-option v-for="(d, index) of studioType" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select> -->

            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>校区状态</span>
              </template>
              <a-radio-group :value="searchData.status">
                <a-radio-button v-for="(d, index) of studioStatus" :key="index" :value="d['value']" @click="handleTypeButChange(d,'status')">
                    {{ d['label'] }}
                </a-radio-button>
              </a-radio-group>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" :loading="loading" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="studio_id"
        :columns="columns" :dataSource="list" @change="handleChange" :scroll="{ x: 1500 }">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="image" slot-scope="text, record">
          <LImg :src="text" style="width: 28px; height: 28px;"/>
        </template>
        <template slot="rate" slot-scope="text, record">
          <a-rate :defaultValue="text" allowHalf disabled />
        </template>
        <template slot="type" slot-scope="text, record">
          <a-tag v-if="text === 1" color="#00cca2">自营</a-tag>
          <a-tag v-if="text === 2" color="#f8ac59">加盟</a-tag>
        </template>
        <template slot="number" slot-scope="text, record">
          <a @click="showRoom(record)">{{text}}</a>
        </template>

        <template slot="tags" slot-scope="tags">
          <span href="javascript:;" v-for="(item, index) of tags" :key="index" >{{ item }}，</span>

          <!-- <a-tag
            v-for="tag in tags"
            :key="tag"
            :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
          >
            {{ tag.toUpperCase() }}
          </a-tag> -->
        </template>

        <template slot="status" slot-scope="text, record">
          <a-switch checkedChildren="开" unCheckedChildren="关" :checked="text === 1" @change="onChangeStatus(record)"/>
        </template>
          <template slot="action" slot-scope="text,record,index">
            <div style="position: relative;">
              <a v-if="canUpdate" href="javascript:;" @click="showSort(index)">排序</a>
              <a-divider v-if="canUpdate" type="vertical" />
              <a v-if="canUpdate" href="javascript:;" @click="showEditModal(record)">编辑</a>
              <a-divider v-if="canUpdate" type="vertical" />
              <a href="javascript:;" v-clipboard:copy="record.access_url" v-clipboard:success="onCopy" v-clipboard:error="onError">复制链接</a>

              <div v-if="isSortModal && sortIndex == index" class="zlgl-sort">
                <span style="margin-right:5px">排列至第</span>
                <a-input-number :value="record.studio_odr" @change="e=>handleSort(e,record)" :min="1"/>
                <span style="margin-left:5px">位</span>
                <a-button @click="handlesortOk(record)" :loading="confirmLoading" style="margin-left:5px" type="primary">确认</a-button>
                <a-button @click="hideRelatedModal" style="margin-left:5px">取消</a-button>
              </div>
            </div>
          </template>
      </a-table>
    </div>
    <div class="page">
      <!-- <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination> -->
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
    <roomModal v-if="isShowRoom" :item="roomData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '校区封面', width: '100px', dataIndex: 'studio_cover', key: 'name',scopedSlots: { customRender: 'image' }, },
  { title: '校区名字', width: '230px', dataIndex: 'studio_name', key: '1' ,sorter:()=>{},ellipsis:true},
  { title: '所在地区', dataIndex: 'province', key: '2', width: 180 ,ellipsis:true},
  { title: '归属部门', dataIndex: 'department_name', key: 'department_name',ellipsis:true },
  { title: '校区电话', dataIndex: 'studio_hotline', key: '3' ,ellipsis:true},
  { title: '校区星级', dataIndex: 'rate', key: '4', width: 160 , scopedSlots: { customRender: 'rate' }},
  { title: '校区标签', dataIndex: 'tags', width: '160px', key: '5', scopedSlots: { customRender: 'tags' },ellipsis:true },
  { title: '教室数', dataIndex: 'room_counts', key: '6' , scopedSlots: { customRender: 'number' }, sorter:()=>{},align:"center"},
  { title: '校区类型', dataIndex: 'type', key: '8',align:"center", scopedSlots: { customRender: 'type' } ,sorter:()=>{}},
  { title: '校区状态', dataIndex: 'status', key: '7',align:"center", scopedSlots: { customRender: 'status' } , sorter:()=>{}},
  { title: '开业时间', dataIndex: 'open_date', key: 'open_date',align:"center" , sorter:()=>{}},
  { title: '操作', key: 'operation', width: 150, align: 'right', scopedSlots: { customRender: 'action' }},
]

import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import roomModal from './roomModal'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
import { TreeSelect } from 'ant-design-vue'
const SHOW_ALL = TreeSelect.SHOW_ALL
import chinaOptions from '@/utils/chinaDivision'

export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
    roomModal,
  },

  data() {
    return {
      SHOW_ALL,
      loading: false,
      confirmLoading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      DepOptions:[],
      chinaDivision:chinaOptions,
      chinaDivisionValue:[],
      studioStatus: C_ITEMS.studioStatus,
      studioType: C_ITEMS.studioType,
      searchData: {status:1},
      visible: false,
      modalData: {},
      editVisible: false,
      isShowRoom:false,
      roomData:null,
      isSortModal: false,
      sortIndex:-1,
      authType:['orginization','studio'],
    }
  },
  mixins: [ authority, tableMixins ],
  created () {
    this.getDepartment()
  },
  methods: {
    closeSearch() {
      this.visible = false
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else if(k === 'department_ids'){
            obj['department_ids'] = searchData[k].map(item=>item.value)
          } else if(k === 'province'){
            if(this.chinaDivisionValue.length>0){
              obj['province'] = this.chinaDivisionValue[0].label
              obj['city'] = this.chinaDivisionValue[1]?this.chinaDivisionValue[1].label:undefined
              obj['district'] = this.chinaDivisionValue[2]?this.chinaDivisionValue[2].label:undefined
            }else{
              obj['province'] = undefined
              obj['city'] = undefined
              obj['district'] = undefined
            }
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('studioListAction', { data: {search:obj}})
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    async getDepartment(){
      let res = await this.$store.dispatch('searchGetDepartmentAction', {})
      this.DepOptions = res.data
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    
    deleteItem(item) {
      let that = this
      return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('studioDeleteAction', { data: { studio_id: item.studio_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error));
    },
    
    recoveryItem(item) {
      let that = this
      return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('studioRecoveryAction', { data: { studio_id: item.studio_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error));
    },
    onChangeStatus(record){
      if (record.status === 1) {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    },
    handleChange(pagination, filters, sorter){
      console.log(pagination, filters, sorter)
      if(sorter.columnKey === '1'){
        if(sorter.order){
          if(sorter.order === "ascend"){
            this.searchParams['sort'] = `${sorter.field}`
          }else{
            this.searchParams['sort'] = `-${sorter.field}`
          }
        }else{
          this.searchParams['sort'] = ''
        }
      }
      if(sorter.columnKey === '6'){
        if(sorter.order){
          if(sorter.order === "ascend"){
            this.searchParams['sort'] = `-${sorter.field}`
          }else{
            this.searchParams['sort'] = `${sorter.field}`
          }
        }else{
          this.searchParams['sort'] = ''
        }
      }
      if(sorter.columnKey === '7'){
        if(sorter.order){
          if(sorter.order === "ascend"){
            this.searchParams['sort'] = `-${sorter.field}`
          }else{
            this.searchParams['sort'] = `${sorter.field}`
          }
        }else{
          this.searchParams['sort'] = ''
        }
      }
      if(sorter.columnKey === '8'){
        if(sorter.order){
          if(sorter.order === "ascend"){
            this.searchParams['sort'] = `${sorter.field}`
          }else{
            this.searchParams['sort'] = `-${sorter.field}`
          }
        }else{
          this.searchParams['sort'] = ''
        }
      }
      this.getList();
    },
    handleTypeButChange(e,name){
      if(this.searchData[name] == e.value){
        this.searchData[name] = undefined
      }else{
        this.searchData[name] = e.value
      }
      this.searchList()
    },
    onCopy(){
      this.$message.success('已复制到剪切板!')
    },
    onError(){
      this.$message.error('操作失败!')
    },
    showRoom(record){
      this.roomData = record
      this.isShowRoom = true
    },
    hideRoom(){
      this.isShowRoom = false
    },
    hideRelatedModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.isSortModal = false
    },
    showSort(index){
      this.isSortModal = true,
      this.sortIndex = index
    },
    handleSort(e,record){
      record.studio_odr = e
    },
    async handlesortOk(item){
      let obj = {
        studio_id : item.studio_id,
        studio_odr : item.studio_odr
      }
      this.confirmLoading = true
      await this.$store.dispatch('studioChangeOrderAction', { data: obj })
      .then(res=>{
          this.$message.success('操作成功~')
          this.getList()
          this.isSortModal = false
          this.confirmLoading = false
      })
      .catch(err=>{
          this.$message.error('操作失败~')
          this.confirmLoading = false
      })
    },
    onChangeOptions(a,b){
      this.chinaDivisionValue = b || []
    },
  }
}
</script>
<style lang="scss" scoped>
.zlgl-sort{
  position: absolute;
  right:0;
  top:-13px;
  width:330px;
  background:#fff;
  padding:5px;
}
</style>
