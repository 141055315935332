<template>
  <div class="zcxy zl-list">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
            <a-button icon="plus" type="primary" @click="showEditModal()">新增</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>标题</span>
              </template>
              <a-input allowClear v-model.trim="searchData.title" placeholder="标题" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>状态</span>
              </template>
              <a-select allowClear v-model="searchData.status" placeholder="请选择状态" style="width: 140px">
                <a-select-option v-for="(d, index) of bannerStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>添加时间</span>
              </template>
              <a-range-picker
              style="width: 240px"
              :ranges="rangesData"
              v-model='searchData.add'
              allowClear
              @change="(val,time)=>handleTime(val,time,'add')" />
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="banner_id"
        :columns="columns" :dataSource="list" >
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="image" slot-scope="text">
          <LImg :src="text" style="width: 150px; height: 72px; margin: 0 5px 5px 0"/>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-switch checkedChildren="开" unCheckedChildren="关" :checked="text === 1" @change="changeStatus(record)"/>
        </template>
        <template slot="action" slot-scope="text,record">
          <a href="javascript:;" @click="showEditModal(record)">编辑</a>
          <!-- <a-divider type="vertical" />
          <a href="javascript:;" @click="deleteItem(record)">删除</a> -->
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: 'Banner图片', width: '100px', dataIndex: 'image',scopedSlots: { customRender: 'image' }, key: 'name'},
  { title: '标题', dataIndex: 'title', key: '1' },
  { title: '跳转地址', dataIndex: 'link', key: '2' },
  { title: '点击统计', dataIndex: 'click_counts', key: '3' },
  { title: '展示次数', dataIndex: 'view_counts', key: '4' },
  { title: '排序', dataIndex: 'sort', key: '5' },
  { title: '状态', dataIndex: 'status', key: '6' , scopedSlots: { customRender: 'status' }},
  { title: '操作', key: 'operation', width: 100, fixed: 'right', scopedSlots: { customRender: 'action' }},
]

import moment from 'moment'
import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import ranges from "@/common/mixins/ranges"
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      bannerStatus: C_ITEMS.bannerStatus,
      studios: [],
      consultants: [],
      channels: [],

      searchData: {},

      visible: false,
      modalData: {},
      editVisible: false,
    }
  },

  mixins:[tableMixins, ranges],

  methods: {
    closeSearch() {
      this.visible = false
    },
    async getList() {
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'add') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('settingBannerAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    
    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要关闭"${item.title}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('settingBannerDeleteAction', { data: { banner_id: item.banner_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    recoveryItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要开启"${item.title}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('settingBannerRecoveryAction', { data: { banner_id: item.banner_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    changeStatus(record){
      if (record.status === 1) {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    },
    handleTime(val,time,name){
        this.searchData[name] = val
        this.$forceUpdate()
    },
  }
}
</script>
