<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="42.5%" @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <div v-if="item.type === 'distpicker'" v-decorator="[item.name, { rules: item.rules }]">
          <a-cascader
            :options="chinaDivision"
            placeholder="请选择"
            v-model="chinaDivisionValue"
            @change="onChangeOptions"/>
        </div>
        <LMap ref='lmap' v-if="item.type === 'map' && mapValue" :valueData="mapValue"/>
        <!-- <LMap ref='lmap' v-if="item.type === 'map'" :valueData="mapValue"/> -->
        <a-rate v-if="item.type === 'rate'" allowHalf v-decorator="[item.name, { rules: item.rules }]"/>

        <div v-decorator="[item.name, { rules: item.rules }]" v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <div v-if="imageUrl">
            <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
            <div class="close" @click="closeImg($event)">&times;</div>
          </div>
          <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
        </div>

        <div v-decorator="[item.name, { rules: item.rules }]" v-if="item.type === 'upload2'" style="display: inline-block; position: relative;">
          <a-button type="dashed" icon="file-image" @click="showImagesModal(item.name)">选择图片</a-button>
          <div class="img-draggable" style="display: flex; flex-wrap: wrap;">
            <draggable v-model="imageUrls">
                <transition-group>
                        <div style="position: relative; margin: 0 10px 10px 0;" v-for="(img, index) of imageUrls" :key="img">
                          <LImg :src="img" style="width: 100px; height: 100px;"/>
                          <div class="close" @click="closeImg2($event, index)">&times;</div>
                        </div>
                </transition-group>
            </draggable>
          </div>
        </div> 
        <div v-if="item.type === 'tags'" >
          <template v-for="(tag, index) in tags">
            <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
              <a-tag :key="tag" closable @close="() => handleClose(tag)">
                {{ `${tag.slice(0, 20)}...` }}
              </a-tag>
            </a-tooltip>
            <a-tag v-else :key="tag" closable @close="() => handleClose(tag)">
              {{ tag }}
            </a-tag>
          </template>
          <a-input
            v-if="inputVisible"
            ref="input"
            type="text"
            size="small"
            autofocus="autofocus"
            :style="{ width: '78px' }"
            :value="inputValue"
            @change="handleInputChange"
            @blur="handleInputConfirm"
            @keyup.enter="handleInputConfirm"
          />
          <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
            <a-icon type="plus" /> 新增
          </a-tag>
        </div>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-radio-group v-else-if="item.type === 'radio'" v-decorator="[item.name, { rules: item.rules }]">
          <a-radio :value="1">
            自营
          </a-radio>
          <a-radio :value="2">
            加盟
          </a-radio>
        </a-radio-group>
        <l-editor v-decorator="[item.name, { rules: item.rules }]" v-else-if="item.type === 'textarea'" name="studio_description" :data="studio_description"/>
        <a-cascader v-else-if="item.type === 'cascader'" v-decorator="[item.name, { rules: item.rules }]" @change="onChangeCascader" :field-names='fieldNames' placeholder="请选择" :options="DepOptions" change-on-select >
          <template slot="displayRender" slot-scope="{ labels, selectedOptions }">
            <span >{{labels[labels.length - 1]}}</span>
          </template>
        </a-cascader>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'studio_cover',
    label: '校区封面',
    type: 'upload',
    rules: [{ required: true, message: '请输入校区封面!' }]
  },
  {
    name: 'rate',
    label: '校区星级',
    type: 'rate',
    rules: [{ required: false, message: '请输入校区星级!' }]
  },
  {
    name: 'studio_name',
    label: '校区名字',
    type: 'input',
    rules: [{ required: true, message: '请输入校区名字!' }]
  },
  {
    name: 'type',
    label: '校区类型',
    type: 'radio',
    rules: [{ required: true, message: '请输入校区类型!' }],
    items: {
      data: 'types',
      label: 'label',
      value: 'value'
    }
  },
  {
    name: 'studio_hotline',
    label: '校区电话',
    type: 'input',
    rules: [{ required: true, message: '请输入校区电话!' }]
  },
  {
    name: 'province',
    label: '省/市/区',
    type: 'distpicker',
    rules: [{ required: true, message: '请选择省/市/区!' }]
  },
  // {
  //   name: 'address',
  //   label: '校区地址',
  //   type: 'map',
  //   rules: [{ required: true, message: '请输入校区地址!' }]
  // },
  {
    name: 'address',
    label: '校区地址',
    type: 'input',
    rules: [{ required: true, message: '请输入校区地址!' }]
  },
  {
    name: 'business_time',
    label: '营业时间',
    type: 'input',
    rules: [{ required: false, message: '请输入营业时间!' }]
  },
  {
    name: 'department',
    label: '归属部门',
    type: 'cascader',
    rules: [{ required: true, message: '请选择归属部门!' }],
    items: {
      data: 'types',
      label: 'label',
      value: 'value'
    }
  },
  {
    name: 'open_date',
    label: '开业时间',
    type: 'date',
    rules: [],
  },
  {
    name: 'tags',
    label: '校区标签',
    type: 'tags',
    rules: [{ required: true, message: '请输入校区标签!' }]
  },
  {
    name: 'studio_images',
    label: '介绍图片(多图)',
    type: 'upload2',
    rules: [{ required: false, message: '请选择介绍图片(多图)!' }]
  },
  {
    name: 'studio_description',
    label: '校区描述',
    type: 'textarea',
    rules: [{ required: false, message: '请输入校区描述!' }]
  },
]
import C_ITEMS from '@/utils/items'
import moment from 'moment'
import url from '@/utils/URL'
import ImageModal from '@/views/modal/images'
import draggable from 'vuedraggable'
import chinaOptions from '@/utils/chinaDivision'

export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
    draggable
  },

  data() {
    return {
      loading:false,
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      ModalTitle: '新开校区',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: [],
        types: C_ITEMS.studioType
      },
      imageUrl: '',
      imageUrls: [],
      imageRoot: url.imageRoot,
      uploadParams: {},
      imagesVisible: false,
      imageKey: '',
      studio_description: '',
      editor: {},
      mapValue: {},
      mapData: {},
      tags: [],
      inputVisible: false,
      inputValue: '',
      DepOptions:[],
      fieldNames:{
        label:'department_name',
        value:'department_id',
        children:'children'
      },
      chinaDivision:chinaOptions,
      chinaDivisionValue:[],
      chinaDivisionValueL:[],
    }
  },

  async created() {
    if(this.item){
      this.loading = true
      this.ModalTitle = '编辑校区 ('+this.item.studio_name+')'
      this.mapValue = {
        address: this.item['address'],
        lng: this.item['lng'],
        lat: this.item['lat'],
      }
    }
    this.visible = true
    await this.$nextTick()
    await this.getClassCategory()
    await this.getDepartment()
    if (this.item) {
      let item = await this.getDetail(this.item.studio_id)
      let formFields = {}
      for (let d of formData) {
        if(d.name == 'rate'){
          formFields[d.name] = item[d.name]
        }
        if(d.name == 'studio_hotline'){
          formFields[d.name] = item[d.name]
        }
        if(d.name == 'type'){
          formFields[d.name] = item[d.name]
        }
        if(d.name == 'studio_name'){
          formFields[d.name] = item[d.name]
        }
        if(d.name == 'business_time'){
          formFields[d.name] = item[d.name]
        }
        if(d.name == 'open_date'){
          formFields[d.name] = moment(item[d.name])
        }
        if(d.name == 'department'){
          formFields[d.name] = item['department_id']
        }
        if(d.name == 'studio_cover'){
          formFields[d.name] = item[d.name]
        }
        if(d.name == 'studio_images'){
          formFields[d.name] = item[d.name]
        }
        if(d.name == 'address'){
          formFields[d.name] = item[d.name]
        }
        if(d.name == 'province'){
          formFields[d.name] = item[d.name]
        }
      }
      this.studio_description = item['studio_description']
      this.imageUrl = item['studio_cover']
      this.imageUrls = item['studio_images']
      this.province = item['province']
      this.city = item['city']
      this.area = item['district']
      this.tags = item['tags']
      this.form.setFieldsValue(formFields)
    }else{
      let formFields = {
        type:1,
        department:[100151]
      }
      this.form.setFieldsValue(formFields)
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getClassCategory() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.seleteItems.classCategorys = res.data
    },
    async getDetail(studio_id) {
      let res = await this.$store.dispatch('studioDetailAction', {data: {studio_id}})
      let chinaDivisionValue = []
      let chinaDivisionValuel = []
      this.chinaDivision.forEach(item=>{
        if(item.label === res.data.province){
          chinaDivisionValue.push(item.value)
          chinaDivisionValuel.push({label:item.label})
          item.children.forEach(it=>{
            if(it.label === res.data.city){
              chinaDivisionValue.push(it.value)
              chinaDivisionValuel.push({label:it.label})
              it.children.forEach(i=>{
                if(i.label === res.data.district){
                    chinaDivisionValue.push(i.value)
                    chinaDivisionValuel.push({label:i.label})
                }
              })
            }
          })
        }
      })
      this.chinaDivisionValue = chinaDivisionValue
      this.chinaDivisionValueL = chinaDivisionValuel
      this.loading = false
      return res.data
    },
    async getDepartment(){
      let res = await this.$store.dispatch('employeeOrganizationAction', {})
      this.DepOptions = res.data
    },
    changeEditor(key, value) {
      this.editor[key] = value
      this.form.setFieldsValue({studio_description:value})
    },
    onChangeOptions(a,b){
      this.chinaDivisionValueL = b
      this.form.setFieldsValue({province :this.chinaDivisionValueL?this.chinaDivisionValueL[0].label:null})
    },
    changeMap(data) {
      console.log(data)
      this.mapData = data
    },
    async handleOk() {
      console.log('ldlcd')
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.studio_id = item.studio_id
          }
          params.department = params.department[params.department.length - 1]
          if(!this.chinaDivisionValueL[2]){
            this.$message.warning('请选择完整的省市区!!')
            return false
          }
          if(params.open_date){
            params.open_date = moment(params.open_date).format('YYYY-MM-DD')
          }
          params.studio_cover = this.imageUrl
          params.studio_images = this.imageUrls 
          params.studio_description = this.editor.studio_description
          params.province = this.chinaDivisionValueL[0].label
          params.city = this.chinaDivisionValueL[1].label
          params.district = this.chinaDivisionValueL[2].label
          // params.address = this.mapData.name
          // params.address = this.$refs.lmap[0].$data.newValue
          // params.lat = this.mapData.lat 
          // params.lng = this.mapData.lng
          params.tags = this.tags
          this.confirmLoading = true
          await this.$store.dispatch(item ? 'studioUpdateAction' : 'studioAddAction', { data: params })
            .then(res=>{
              form.resetFields()
              this.parent.hideEditModal(1)
            })
            .catch(err=>{
              this.confirmLoading = false
            })
        } 
      } catch {
      }
			
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
      this.form.setFieldsValue({studio_cover:this.imageUrl})
    },
    
    closeImg2(e, index) {
      e.stopPropagation()
      this.imageUrls.splice(index, 1)
      this.form.setFieldsValue({studio_images:this.imageUrls})
    },

    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        if (this.imageKey === 'studio_images') {
          this.imageUrls.push(image.path)
          this.form.setFieldsValue({studio_images:this.imageUrls})
        } else {
          this.imageUrl = image.path
          this.form.setFieldsValue({studio_cover:this.imageUrl})
        }
      }
      this.imagesVisible = false
    },

    handleClose(removedTag) {
      const tags = this.tags.filter(tag => tag !== removedTag);
      this.tags = tags;
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(function() {
        this.$refs.input[0].focus();
      });
    },

    handleInputChange(e) {
      this.inputValue = e.target.value;
    },

    handleInputConfirm() {
      const inputValue = this.inputValue;
      let tags = this.tags;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      Object.assign(this, {
        tags,
        inputVisible: false,
        inputValue: '',
      });
    },
    onChangeCascader(){

    }


  }
}
</script>

<style lang="scss">
  .img-draggable{
    span{
      display: flex;
      flex-wrap: wrap;
    }
  }
</style>
