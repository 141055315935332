<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>校区名字</span>
              </template>
              <a-input allowClear v-model.trim="searchData.studio_name" placeholder="校区名字" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>省市区</span>
              </template>
              <a-cascader :options="chinaDivision" :showSearch="true" placeholder="请选择省市区" change-on-select v-model="searchData.province" @change="onChangeOptions" style="width: 160px" />
            </a-tooltip>
          </a-form-item>
          <a-form-item>
              <a-tree-select
                  v-model="searchData.department_ids"
                  style="width:160px"
                  :tree-data="DepOptions"
                  tree-checkable
                  allowClear
                  :replaceFields="{
                      title:'department_name',
                      value: 'department_id',
                  }"
                  treeNodeFilterProp="title"
                  :maxTagCount='1'
                  :maxTagTextLength='5'
                  :dropdownStyle="{maxHeight:'400px'}"
                  :show-checked-strategy="SHOW_ALL"
                  :treeCheckStrictly="true"
                  placeholder="请选择部门"
              />
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>校区类型</span>
              </template>
              <a-radio-group :value="searchData.type">
                <a-radio-button v-for="(d, index) of studioType" :key="index" :value="d['value']" @click="handleTypeButChange(d,'type')">
                    {{ d['label'] }}
                </a-radio-button>
              </a-radio-group>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>校区状态</span>
              </template>
              <a-radio-group :value="searchData.status">
                <a-radio-button v-for="(d, index) of studioStatus" :key="index" :value="d['value']" @click="handleTypeButChange(d,'status')">
                    {{ d['label'] }}
                </a-radio-button>
              </a-radio-group>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" :loading="loading" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>

         <baidu-map class="bm-view" center="上海" :zoom="14" :scroll-wheel-zoom="true"  @ready="setDistanceToolInstance">
            
              <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
              <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>
              <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
              <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
              <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale>
              <bml-marker-clusterer :averageCenter="true">
                  <bm-marker v-for="(maker,index) in bmaker" :key="index" v-if="maker.position.lng" :position="maker.position" :dragging="true" :title="maker.studio_name">
                      <bm-label :content="maker.studio_name" :labelStyle="{color: 'red', fontSize : '12px'}" :offset="{width: -35, height: 30}"/>
                  </bm-marker>
              </bml-marker-clusterer>
              <bm-circle v-for="(maker,index) in bmaker" :key="index" v-if="maker.position.lng" :position="maker.position" :center="maker.position" radius="500" :fillOpacity="0.1" stroke-color="" :stroke-opacity="0.5" :stroke-weight="2.5" :editing="false"></bm-circle>
              <bm-control>
                <a-button icon="environment" size="small" @click="openDistanceTool" style="margin-left:80px;top:10px;font-size:12px;">测距</a-button>
              </bm-control>
          </baidu-map>

    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '校区封面', width: '100px', dataIndex: 'studio_cover', key: 'name',scopedSlots: { customRender: 'image' }, },
  { title: '校区名字', width: '230px', dataIndex: 'studio_name', key: '1' ,sorter:()=>{},ellipsis:true},
  { title: '所在地区', dataIndex: 'province', key: '2', width: 180 ,ellipsis:true},
  { title: '归属部门', dataIndex: 'department_name', key: 'department_name',ellipsis:true },
  { title: '校区电话', dataIndex: 'studio_hotline', key: '3' ,ellipsis:true},
  { title: '校区星级', dataIndex: 'rate', key: '4', width: 160 , scopedSlots: { customRender: 'rate' }},
  { title: '校区标签', dataIndex: 'tags', width: '160px', key: '5', scopedSlots: { customRender: 'tags' },ellipsis:true },
  { title: '教室数', dataIndex: 'room_counts', key: '6' , scopedSlots: { customRender: 'number' }, sorter:()=>{},align:"center"},
  { title: '校区类型', dataIndex: 'type', key: '8',align:"center", scopedSlots: { customRender: 'type' } ,sorter:()=>{}},
  { title: '校区状态', dataIndex: 'status', key: '7',align:"center", scopedSlots: { customRender: 'status' } , sorter:()=>{}},
  { title: '开业时间', dataIndex: 'open_date', key: 'open_date',align:"center" , sorter:()=>{}},
  { title: '操作', key: 'operation', width: 150, align: 'right', scopedSlots: { customRender: 'action' }},
]

import C_ITEMS from '@/utils/items'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
import { TreeSelect } from 'ant-design-vue'
const SHOW_ALL = TreeSelect.SHOW_ALL
import chinaOptions from '@/utils/chinaDivision'
import DistanceTool from 'bmaplib.distancetool'
import {BmlMarkerClusterer,BmlHeatmap} from 'vue-baidu-map'

export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },
  components: {
    BmlMarkerClusterer
  },
  data() {
    return {
      SHOW_ALL,
      loading: false,
      confirmLoading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      DepOptions:[],
      chinaDivision:chinaOptions,
      chinaDivisionValue:[],
      studioStatus: C_ITEMS.studioStatus,
      studioType: C_ITEMS.studioType,
      searchData: {status:1},
      visible: false,
      modalData: {},
      editVisible: false,
      isShowRoom:false,
      roomData:null,
      isSortModal: false,
      sortIndex:-1,
      bmaker:[],
      authType:['orginization','studio'],
    }
  },
  mixins: [ authority, tableMixins ],
  created () {
    this.getDepartment()
  },
  unmount () {
      distanceTool && distanceTool.close()
  },
  methods: {
    closeSearch() {
      this.visible = false
    },
    setDistanceToolInstance ({map}) {
        this.distanceTool = new DistanceTool(map, {lineStroke : 2})
        console.log(this.distanceTool)
    },
    openDistanceTool (e) {
        const {distanceTool} = this
        distanceTool && distanceTool.open()
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else if(k === 'department_ids'){
            obj['department_ids'] = searchData[k].map(item=>item.value)
          } else if(k === 'province'){
            if(this.chinaDivisionValue.length>0){
              obj['province'] = this.chinaDivisionValue[0].label
              obj['city'] = this.chinaDivisionValue[1]?this.chinaDivisionValue[1].label:undefined
              obj['district'] = this.chinaDivisionValue[2]?this.chinaDivisionValue[2].label:undefined
            }else{
              obj['province'] = undefined
              obj['city'] = undefined
              obj['district'] = undefined
            }
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('studioListAction', { data: {search:obj}})
      this.list = res.items


      this.bmaker = []
      let that = this;
      this.list.forEach(function(item, index) {
        if(item.position){
          that.bmaker.push(item);
        }
      })
      // 

      this.pageParams = res._meta
      this.loading = false
    },
    async getDepartment(){
      let res = await this.$store.dispatch('searchGetDepartmentAction', {})
      this.DepOptions = res.data
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    
    deleteItem(item) {
      let that = this
      return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('studioDeleteAction', { data: { studio_id: item.studio_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error));
    },
    
    recoveryItem(item) {
      let that = this
      return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('studioRecoveryAction', { data: { studio_id: item.studio_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error));
    },
    onChangeStatus(record){
      if (record.status === 1) {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    },
    handleChange(pagination, filters, sorter){
      console.log(pagination, filters, sorter)
      if(sorter.columnKey === '1'){
        if(sorter.order){
          if(sorter.order === "ascend"){
            this.searchParams['sort'] = `${sorter.field}`
          }else{
            this.searchParams['sort'] = `-${sorter.field}`
          }
        }else{
          this.searchParams['sort'] = ''
        }
      }
      if(sorter.columnKey === '6'){
        if(sorter.order){
          if(sorter.order === "ascend"){
            this.searchParams['sort'] = `-${sorter.field}`
          }else{
            this.searchParams['sort'] = `${sorter.field}`
          }
        }else{
          this.searchParams['sort'] = ''
        }
      }
      if(sorter.columnKey === '7'){
        if(sorter.order){
          if(sorter.order === "ascend"){
            this.searchParams['sort'] = `-${sorter.field}`
          }else{
            this.searchParams['sort'] = `${sorter.field}`
          }
        }else{
          this.searchParams['sort'] = ''
        }
      }
      if(sorter.columnKey === '8'){
        if(sorter.order){
          if(sorter.order === "ascend"){
            this.searchParams['sort'] = `${sorter.field}`
          }else{
            this.searchParams['sort'] = `-${sorter.field}`
          }
        }else{
          this.searchParams['sort'] = ''
        }
      }
      this.getList();
    },
    handleTypeButChange(e,name){
      if(this.searchData[name] == e.value){
        this.searchData[name] = undefined
      }else{
        this.searchData[name] = e.value
      }
      this.searchList()
    },
    onCopy(){
      this.$message.success('已复制到剪切板!')
    },
    onError(){
      this.$message.error('操作失败!')
    },
    showRoom(record){
      this.roomData = record
      this.isShowRoom = true
    },
    hideRoom(){
      this.isShowRoom = false
    },
    hideRelatedModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.isSortModal = false
    },
    showSort(index){
      this.isSortModal = true,
      this.sortIndex = index
    },
    handleSort(e,record){
      record.studio_odr = e
    },
    async handlesortOk(item){
      let obj = {
        studio_id : item.studio_id,
        studio_odr : item.studio_odr
      }
      this.confirmLoading = true
      await this.$store.dispatch('studioChangeOrderAction', { data: obj })
      .then(res=>{
          this.$message.success('操作成功~')
          this.getList()
          this.isSortModal = false
          this.confirmLoading = false
      })
      .catch(err=>{
          this.$message.error('操作失败~')
          this.confirmLoading = false
      })
    },
    onChangeOptions(a,b){
      this.chinaDivisionValue = b || []
    },
  }
}
</script>
<style lang="scss" scoped>
.bm-view {
    width: 100%;
    height: 720px;
}
.zlgl-sort{
  position: absolute;
  right:0;
  top:-13px;
  width:330px;
  background:#fff;
  padding:5px;
}
</style>
