<template>
  <a-modal title="校区教室" v-model="visible" :confirmLoading="confirmLoading" :footer="null"
    cancelText="取消" okText="保存" width="42.5%" @ok="handleOk" @cancel="handleCancel">
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                <a-form layout='inline'>
                    <a-form-item>
                    <a-button icon="plus" type="primary" @click="showEditModal()">新增教室</a-button>
                    </a-form-item>
                </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="room_id"
                :columns="columns" :dataSource="list">
                <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                </template>
                <template slot="room_name" slot-scope="text,record">
                    <span style="color:#00cca2;cursor:pointer;" @click="showEditModal(record)">{{text}}</span>
                </template>
                <template slot="status" slot-scope="text,record">
                    <a-switch checkedChildren="开" unCheckedChildren="关" :checked="text === 1" @change="changeStatus(record)"/>
                </template>
                <template slot="action" slot-scope="text,record">
                    <a href="javascript:;" @click="showEditModal(record)">编辑</a>
                </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
        <editModal v-if="editVisible" :isEdit='isEdit' :item="modalData"/>
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index' },
  { title: '教室名字', dataIndex: 'room_name', key: 'room_name',scopedSlots: { customRender: 'room_name' }},
  { title: '所属校区', dataIndex: 'studio_id', key: 'studio_id'},
  { title: '容纳人数', dataIndex: 'room_people', key: '1',align:"center"},
//   { title: '教室成本', dataIndex: 'room_price', key: '2' ,align:"right"},
//   { title: '开课数', dataIndex: 'classes_count', key: '3' ,align:"right"},
//   { title: '状态', dataIndex: 'room_status', key: '11', width: 80, scopedSlots: { customRender: 'status' }},
  { title: '操作', key: 'operation', width: 46, scopedSlots: { customRender: 'action' }},
]

import editModal from '../../room/editModal'
import tableMixins from '@/common/mixins/table'


    export default {
        name:'roomModal',
        inject: ['parent'],
        mixins: [ tableMixins ],
        data() {
            return {
                visible: false,
                loading:false,
                columns,
                list:[],
                confirmLoading:false,
                pageSizeOptions: ['10', '20', '30', '40', '100'],
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                editVisible:false,
                isEdit:0,
                modalData:null
            }
        },
        props:{
            item:Object
        },
        components: {
            editModal,
        },
        created () {
            this.visible = true
            this.getList()
        },
        beforeDestroy () {
            this.visible = false
        },
        methods: {
            async getList() {
                this.loading = true
                let obj = {studio_id:this.item.studio_id}
                this.searchParams.search = obj
                let res = await this.$store.dispatch('roomIndexAction', { data: this.searchParams })
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            handleOk() {
                
            },
            handleCancel(){
                this.parent.hideRoom()
            },
            showEditModal(item) {
                if(item){
                    this.isEdit = 1
                }else{
                    this.isEdit = 2
                }
                this.modalData = item
                this.editVisible = true
            },
            hideEditModal(type) {
                if (type === 1) {
                    this.getList()
                }
                this.editVisible = false
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>