<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="450px"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form"  layout="vertical">

      <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
        
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>

        <a-input-number style='width:100%' v-if="item.type === 'number'" :min="0" v-decorator="[item.name, { rules: item.rules }]" />

        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>

        <a-select v-else-if="item.type === 'select'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :disabled="isEdit != 0" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>

        <a-textarea v-else-if="item.type === 'textarea'" :rows="4" v-decorator="[item.name, { rules: item.rules }]"/>

      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'studio_id',
    label: '所属校区',
    type: 'select',
    rules: [{ required: true, message: '请选择所属校区!' }],
    items: {
      data: 'classCategorys',
      label: 'filter_name',
      value: 'studio_id'
    }
  },
  {
    name: 'room_name',
    label: '教室名字',
    type: 'input',
    rules: [{ required: true, message: '请输入教室名字!' }]
  },
  {
    name: 'room_people',
    label: '容纳人数',
    type: 'number',
    rules: [{ required: true, message: '请输入容纳人数!' }]
  },
  // {
  //   name: 'room_price',
  //   label: '教室成本',
  //   type: 'input',
  //   rules: []
  // },
]
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object,
    isEdit: Number,
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增教室',
      visible: false,
      loading: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: []
      }
    }
  },

  async created() {
    this.visible = true
    if(this.item){
      this.loading = true
    }
    await this.$nextTick()
    await this.getClassCategory()
    if (this.item) {
      this.ModalTitle = '编辑教室'
      let item = await this.getDetail(this.item.room_id)
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      this.form.setFieldsValue(formFields)
    }
    if(this.isEdit === 2){
      this.form.setFieldsValue({studio_id:this.parent.item.studio_id})
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getClassCategory() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.seleteItems.classCategorys = res.data
    },
    async getDetail(room_id) {
      let res = await this.$store.dispatch('roomDetailAction', {data: {room_id}})
      this.loading = false
      return res.data
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.room_id = item.room_id
          }
          let res = await this.$store.dispatch(item ? 'roomUpdateAction' : 'roomAddAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideEditModal(1)
          }
        }
      } catch {

      }
      this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    }
  }
}
</script>
