<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="35.5%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" layout="vertical">
      <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input v-if="item.type === 'link'" placeholder="请输入https://h5.wedots.cn这个域名下的链接" v-decorator="[item.name, { rules: item.rules }]"/>

        <a-input-number style="width:100%" v-if="item.type === 'input-number'" v-decorator="[item.name, { rules: item.rules }]"></a-input-number>
        
        <div v-decorator="[item.name, { rules: item.rules }]" v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <div v-if="imageUrl">
            <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
            <div class="close" @click="closeImg($event)">&times;</div>
          </div>
          <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
        </div>

        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-radio-group v-else-if="item.type === 'radio'" v-decorator="[item.name, { rules: item.rules }]">
          <a-radio :value="1">
            有效
          </a-radio>
          <a-radio :value="0">
            无效
          </a-radio>
        </a-radio-group>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'image',
    label: 'Banner图片 (尺寸 750*360)',
    type: 'upload',
    rules: [{ required: true, message: '请输入Banner图片!' }]
  },
  {
    name: 'title',
    label: '标题',
    type: 'input',
    rules: [{ required: true, message: '请输入标题!' }]
  },
  {
    name: 'link',
    label: '跳转地址',
    type: 'link',
    rules: [{ required: true, message: '' },{validator:linkCheck.bind(this)}]
  },
  {
    name: 'sort',
    label: '排序（从小到大）',
    type: 'input-number',
    rules: []
  },
  {
    name: 'status',
    label: '状态',
    type: 'radio',
    rules: [],
    items: {
      data: 'bannerStatus',
      label: 'label',
      value: 'value'
    }
  }
]
import C_ITEMS from '@/utils/items'
import url from '@/utils/URL'
import ImageModal from '@/views/modal/images'

function linkCheck (rule, value, callbackFn) {
  if(!value){
    callbackFn('请输入链接!')
    return
  }
  if (value.indexOf('https://h5.wedots.cn') == -1) {
    callbackFn('请输入https://h5.wedots.cn这个域名下的链接!')
    return
  }
  callbackFn()
}

export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增焦点图',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        bannerStatus: C_ITEMS.bannerStatus,
      },
      
      imageUrl: '',
      imageRoot: url.imageRoot,
      uploadParams: {},
      imagesVisible: false,
      imageKey: '',
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    if (this.item) {
      this.ModalTitle = '编辑焦点图'
      let { item } = this
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      this.imageUrl = item['image']
      this.form.setFieldsValue(formFields)
    }else{
      this.form.setFieldsValue({status:1})
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.banner_id = item.banner_id
          }
          params.image = this.imageUrl
          let res = await this.$store.dispatch(item ? 'settingBannerUpdateAction' : 'settingBannerAddAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideEditModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
      this.form.setFieldsValue({image:this.imageUrl})
    },

    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        this.imageUrl = image.path
        this.form.setFieldsValue({image:this.imageUrl})
      }
      this.imagesVisible = false
    },
  }
}
</script>
