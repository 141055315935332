<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>校区管理</a-breadcrumb-item>
      <a-breadcrumb-item>校区信息</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">校区管理<a-badge :count="0" /></span>
          <xqgl v-if="activeKey==='1'"/>
        </a-tab-pane>

        <a-tab-pane key="2">
          <span slot="tab">校区教室<a-badge :count="0" /></span>
          <room v-if="activeKey==='2'"/>
        </a-tab-pane>

        <a-tab-pane key="3">
          <span slot="tab">校区地图<a-badge :count="0" /></span>
          <xqdt v-if="activeKey==='3'"/>
        </a-tab-pane>
        <!-- <a-tab-pane key="2">
          <span slot="tab">焦点图管理<a-badge :count="0" /></span>
          <jdtgl v-if="activeKey==='2'"/>
        </a-tab-pane> -->
        <!-- <a-tab-pane key="3">
          <span slot="tab">通知设置<a-badge :count="0" /></span>
          <tzsz v-if="activeKey==='3'"/>
        </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import xqgl from './xqgl/index'
import xqdt from './xqdt/index'
import room from '../room/index'
import jdtgl from './jdtgl/index'
import tzsz from './tzsz/index'

export default {
  components: {
    xqgl,
    xqdt,
    jdtgl,
    tzsz,
    room
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

